.lang-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 2rem;
    cursor: pointer;

    svg {
        margin-left: 1rem;
        transform: scale(.8);
    }
}

.lang-dropdown {
    .ant-dropdown-menu-item, 
    .ant-dropdown-menu-submenu-title {
        padding: 1rem 4rem;
    }
}