.user-image {
    width: 6rem;
    height: 6rem;

    .image {
        width: inherit;
        height: inherit;
        border-radius: 1rem;
        overflow: hidden;
    }
}