.image-container {
    position: relative;
    background-color: var(--color-passive-stripped-light);
    font-size: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: opacity .2s ease;

        &.loading {
            opacity: .5;
        }
    }

    .image-spinner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}