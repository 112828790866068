.sso-layout.error {
    .ant-btn {
        margin-top: 2em;
        padding: .5em 1em;
        height: auto;

        &.ghost {
            background-color: transparent;
            border-color: currentColor;
        }
    }
}

@media (prefers-color-scheme: dark) {
    .sso-layout.error {
        .ant-btn {
            &.ghost {
                color: var(--color-passive-medium-mark);
            }
        }
    }
}