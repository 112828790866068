.sign-methods {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem;
    width: 100%;

    .registration {
        margin-bottom: 1rem;

        a {
            color: inherit;
            transition: color 0.25s; 

            &:hover {
                color: var(--color-passive-light);
            }
        }
    }

    .apps {
        display: flex;
        align-items: center;

        &:first-child:last-child {
            margin-left: auto;
        }
    }

    .app {
        display: flex;
        align-items: center;
        color: inherit;
        transition: color 0.25s; 
        
        &:hover {
            color: var(--color-passive-light);
        }

        &:not(:first-child) {
            margin-left: 2rem;
        }
        &:not(:last-child) {
            margin-right: 2rem;
        }
    }

    .app-icon {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 1rem;
    }
}

@media (max-width: 660px) {
    .sign-methods {
        flex-direction: column;

        .apps {
            flex-direction: column;
            margin-top: 2rem;

            .app {
                margin: 0 0 1rem 0;
            }
        }
    }
}