.tabee-login__offset-wrap {
    margin-top: 1rem;
}

.ant-form {
    margin: 1rem 0;

    .ant-row {
        display: flex;
        align-items: flex-start;
        text-align: left;
        margin-bottom: 0;

        & + & {
            margin-top: 1px;
        }

        &.offset {
            margin-top: 1rem;
        }
    }
      
    .form-row-label {
        display: flex;
        align-items: center;
        width: 18rem;
        min-height: 4rem;
        margin: 0.5rem 0;
        color: var(--color-passive-mark);
    }
      
    .form-row-control {
        width: 29rem;
        min-height: 4rem;
        margin: 0.5rem 0;

        &:first-child {
            margin-left: 18rem;
        }
        & + & {
            width: auto;
            margin-left: 2rem;
        }
    }
      
    .form-control {
        padding: 1rem 0;
        text-align: center;
    }

    .ant-form-item-control-input-content .ant-picker {
        width: 100%;
    }
}

.ant-form-item-label > label {
    font-size: 1.625rem;
}

.ant-form-item-label > label::after {
    display: none;
}

.label-in {
    position: relative;
    color: inherit;

    .label-in-label {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        padding: 8px 0;
        color: inherit;
        opacity: .5;
        font: 600 16px / 4rem 'Open Sans', Arial, sans-serif;
        transition: all .2s ease;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    input[value]:not([value=""]) + .label-in-label {
        font-size: 12px;
        line-height: 12px;
        padding: 0;
    }
}
.ant-input,
.ant-input-password,
.ant-input-affix-wrapper {
    width: 100%;
    height: 4rem;
    padding: 0 1rem;
    background: var(--color-passive-light);
    border: 1px solid var(--color-passive-medium);
    color: var(--color-active-dark);
    transition: all 0.25s;
    margin-bottom: 1px;
    border-radius: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.7rem;
    line-height: 4rem;
    
    &:not(:disabled):focus {
        background-color: var(--color-passive-light);
        border-color: var(--color-active-brand);
    }
    &:disabled,
    &[readonly] {
        color: var(--color-passive-mark);
    }

    &:-internal-autofill-selected {
        color: var(--color-active-dark) !important;
        -webkit-text-fill-color: var(--color-active-dark);
    }

    &.dark-theme {
        background-color: var(--color-passive-dark);
        border-color: var(--color-passive-dark);
        color: var(--color-passive-light);
        box-shadow: inset 0 0 0 6.25rem var(--color-passive-dark);
        -webkit-text-fill-color: var(--color-passive-light);
        caret-color: var(--color-passive-light);

        &::placeholder {
            -webkit-text-fill-color: var(--color-passive-light);
            color: var(--color-passive-light);
            opacity: .5;
        }
        
        &:not(:disabled):focus,
        &:not(.ant-input-affix-wrapper-disabled):hover {
            background-color: var(--color-passive-dark);
            border-color: var(--color-passive-dark);
        }
        &:disabled,
        &[readonly] {
            color: var(--color-passive-mark);
            -webkit-text-fill-color: var(--color-passive-mark);
        }

        .ant-input,
        .ant-input:focus {
            box-shadow: inset 0 0 0 6.25rem var(--color-passive-dark);
        }

        .ant-input::placeholder {
            -webkit-text-fill-color: var(--color-passive-light);
            color: var(--color-passive-light);
            opacity: .5;
        }

        .ant-input-password-icon {
            color: var(--color-passive-mark);
        }
    }

    .ant-input {
        border: none;
        background: transparent !important;
        color: currentColor !important;
        height: auto;
    }
}

.ant-input-affix-wrapper-disabled {
    cursor: not-allowed;

    &:hover {
        border-color: var(--color-passive-medium);
    }

    * {
        pointer-events: none;
    }
}

.ant-checkbox-wrapper {

    &.align-center,
    &.tabee-login__remember {
        width: 100%;
        text-align: center;
    }

    &:hover {
        & {
            outline: none !important;
            box-shadow: none !important;
        }
    }

    &.small-size,
    &.tabee-login__remember {
        .ant-checkbox-inner {
            width: 2rem;
            height: 2rem;

            &:after {
                left: 10%;
                transform: scale(.7) translate(40%,-80%) rotate(45deg) ;
            }
        }
    }

    .ant-checkbox-checked {
        &:after {
            display: none;
        }
        
        .ant-checkbox-inner {
            border-color: var(--color-active-brand);
            color: var(--color-passive-light);

            &:before {
                transform: scale(1.1);
            }
        }
    }

    .ant-checkbox-inner {
        width: 3rem;
        height: 3rem;
        background: var(--color-passive-light);
        border: 1px solid;
        border-radius: 100%;
        color: var(--color-passive-mark);
        cursor: inherit;
        transition: all 0.25s;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--color-active-brand);
            border-radius: inherit;
            transition: transform 0.25s;
            transform: scale(0);
        }

        &:after {
            left: 26%;
            height: 1.5rem;
            width: .9rem;
            border-width: .375rem;
            transition: none;
        }
    }
    
    &.dark-theme {
        color: var(--color-passive-light);

        &.small-size,
        &.tabee-login__remember {
            .ant-checkbox-inner {
                &:after {
                    left: 10%;
                }
            }
        }
        
        .ant-checkbox-inner {
            background-color: var(--color-passive-dark);
            border-color: var(--color-passive-dark);
            color: var(--color-passive-dark);
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: var(--color-active-brand);
        }

        .ant-checkbox-disabled {
            .ant-checkbox-inner {
                opacity: .5;
                border-color: transparent !important;

                &:after {
                    border-color: #fff;
                }
            }

            & + span {
                color: rgba(255,255,255,.5);
            }
        }
    }
}
.ant-btn {
    display: inline-block;
    height: 4rem;
    padding: 0 1rem;
    border-radius: 2px !important;
    background: var(--color-passive-light);
    border: 1px solid var(--color-passive-medium);
    color: var(--color-base-dark);
    cursor: pointer;
    user-select: none;

    &:active,
    &:focus {
        color: var(--color-base-dark);
    }

    &:hover {
        color: var(--color-active-brand);
    }

    &.ant-btn-text,
    &.ant-btn-link {
        border: none;
    }

    &.ant-btn-text {
        color: var(--color-passive-mark);
    }
    
    &[disabled] {
        cursor: default;
    }
    
    & span {
        font-weight: 600;
    }

    &.centered {
        display: block;
        margin: 0 auto;
    }
    
    &.fit-container,
    &.tabee-login__submit {
        width: 100%;

        &.high {
            padding-top: 2rem;
            padding-bottom: 2rem;
            height: auto;
        }
    }
    
    &.ant-btn-primary {
        background-color: var(--color-active-brand);
        border-color: var(--color-active-brand);
        color: var(--color-passive-light);
        
        &[disabled] {
            background: var(--color-passive-light);
            border: 1px solid var(--color-passive-medium);
            border-radius: 1px;
            color: var(--color-passive-mark);
        }

        &.ant-btn-dangerous {
            background-color: var(--color-active-alert);
            border-color: var(--color-active-alert);
        }
    }

    &.ant-btn-icon-only {
        padding: 0;
        font-size: 0;

        &.no-border {
            border: none !important;
            background-color: transparent !important;
        }

        &.smaller > * {
            font-size: 1rem;

            svg {
                transform: scale(.85);
            }
        }
    }
}


.title-fix {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 4rem;
}

.login-links {
    padding: 4rem 0;
}
  
.login-link {
    display: flex;
    justify-content: center;
    color: inherit;
    transition: color 0.25s; 

    &:hover {
        color: var(--color-active-brand);
    }
}

.account-content {
    .ant-btn.ant-btn-primary[disabled] {
        background-color: var(--color-faded-brand);
        border-color: var(--color-faded-brand);
        color: rgba(255,255,255,.5);
    }
}

.login-message,
.tabee-login__message {
    text-align: center;
    white-space: nowrap;
}

.tabee-login__message {
    font-size: .9em;
    opacity: .5;
    margin-top: 2rem;
    color: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}