.social-networks-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .lang-switch {
        margin-left: 2rem;
        margin-right: auto;
    }

    .social-networks {
        display: flex;
        align-items: center;
    }

    .social-networks-label {
        margin-right: 1rem;
    }
      
    .social-networks-items {
        display: flex;
        align-items: center;
    }
      
    .social-network {
        display: flex;
        align-items: center;
        margin: 0 1rem;
        color: inherit;
        transition: color 0.25s; 
    
        &:hover {
            color: var(--color-passive-light);
        }
      
        .social-network-icon {
            width: 2.5rem;
            height: 2.5rem;
        }
    }  
}
@media (max-width: 660px) {
    .social-networks-wrap {
        flex-wrap: wrap;

        .site-navigation {
            flex: 1 1 100%;
            margin-bottom: 2rem;
        }

        .lang-switch {
            margin-left: 0;
        }
    }
}