.tabee-logo {
    display: inline-block;
    width: 22.5rem;
    height: 4rem;
  
    .image {
        display: block;
        width: inherit;
        height: inherit;
    }
}