.sso-layout {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: var(--color-active-dark);
    color: var(--color-passive-mark);
    overflow-y: auto;
    margin: 0 auto;

    &.login {
        height: 100%;
        overflow: hidden;
    }

    &.locked {
        cursor: wait;

        * {
            pointer-events: none !important;
        }
    }
}
  