.account-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
    color: var(--color-passive-light);
    max-width: 100%;

    .account-header {
        text-align: center;
        padding: 0 4rem;

        .title-fix.small {
            font-size: .8em;
            line-height: 1.2em;
        }
    }
    
    .logo {
        padding: 4rem 0;
    }
    
    .account-content {
        width: 48rem;
        padding: 4rem 0;
        max-width: calc(100% - 4rem);

        .ant-form {
            width: 32rem;
            margin: 0 auto;
        }
    }
}