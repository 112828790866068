.account-methods {
    margin-top: 4rem;
}

.sso-layout.profile {
    .page-title {
        display: none;
    }
}

@media (max-width: 720px) {
    .sso-layout.profile {
        .sign-methods,
        .account-footer {
            display: none;
        }

        .page-title {
            display: block;
            margin-bottom: 32px;
        }

        .title-fix.small {
            font-size: 1em;
            text-align: left;
        }

        .account-container {
            justify-content: space-between;
            padding-bottom: 0;

            .logo {
                padding: 0;

                &:after {
                    content: "";
                    background: url(https://server.tabee.mobi/static/tabee/images/mails/dark/divider.png) no-repeat 50%;
                    background-size: contain;
                    display: block;
                    margin-bottom: 32px;
                    height: 25px;
                }

                .tabee-logo {
                    max-width: 16rem;

                    img {
                        max-width: 100%;
                        object-fit: contain;
                    }
                }
            }
        }

        .account-content {
            flex: 1 1 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 0;
        }

        .account-methods {
            margin-top: auto;

            .card,
            .card:first-child {
                border: none;
            }
        }

        .card {
            border-bottom: 1px solid var(--color-base-dark);
            margin-left: -2rem;
            margin-right: -2rem;
            padding-left: 3rem;
            padding-right: 2rem;

            &:first-child {
                border-top: 1px solid var(--color-base-dark);
            }

            .company-name {
                display: none;
            }
        }
    }
}